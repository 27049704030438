/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    img: "img"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://foter.com/photo/solar-panels-7/"
  }, React.createElement(_components.img, {
    src: "images/solar-panels-7_l.jpg",
    alt: ""
  }))), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://www.flickr.com/photos/jeremylevinedesign/2903370723/"
  }, "Jeremy Levine Design"), " / ", React.createElement(_components.a, {
    href: "http://foter.com"
  }, "Foter"), " / ", React.createElement(_components.a, {
    href: "http://creativecommons.org/licenses/by/2.0/"
  }, "CC BY")), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://lasindias.com/totalitarismo-contra-energia-distribuida/"
  }, "Varios"), " ", React.createElement(_components.a, {
    href: "http://planet.communia.org/content/energ%C3%ADa-central"
  }, "blogs"), " ", React.createElement(_components.a, {
    href: "http://juanjopina.fileaesir.com/quien-teme-a-la-autoproduccion/"
  }, "se han hecho eco"), " de ", React.createElement(_components.a, {
    href: "http://sociedad.elpais.com/sociedad/2013/07/19/actualidad/1374237478_141414.html"
  }, "la nueva ley del gobierno español"), " para la puesta en marcha de peajes al autoconsumo,  no sólo ", React.createElement(_components.a, {
    href: "http://solartradex.com/la-reforma-energetica/"
  }, "los que se"), " ", React.createElement(_components.a, {
    href: "http://solartradex.com/vayase-ministro-soria-nos-ha-mentido/"
  }, "dedican a"), " ", React.createElement(_components.a, {
    href: "http://www.energias-renovables.com/articulo/eurosolar-promueve-una-contestacion-social-masiva-en-20130719"
  }, "este negocio"), " están asustad por las consecuencias de esta medida, todos deberíamos estar preguntándonos cómo vamos a sostener nuestro consumo propio de energía si nos impiden el acceso al (no)mercado energético y los precios pactados de la electricidad cada vez suben más."), "\n", React.createElement(_components.p, null, "Esto es, lo miremos como lo miremos, esclavismo energético, en una sociedad cada vez más dependiente de la energía para todo y que vamos a ver en los próximos años como aumenta su consumo de electricidad de una forma brutal con la llegada del coche eléctrico, no se puede permitir. La red eléctrica que queremos es una red distribuida, móvil, eficiente y responsable con el medio ambiente, la autoproducción debe ser YA la opción por defecto, ya se ha demostrado rentable la energía eólica y ahora va camino de serlo la solar, no le podemos poner trabas a esto y ", React.createElement(_components.a, {
    href: "http://lasindias.net/indianopedia/Principio_de_generaci%C3%B3n_de_escasez"
  }, "generar una escasez artificial"), " de forma tan descarada para la perpetuación de un oligopolio que ya está terminal."), "\n", React.createElement(_components.p, null, "Esto es un grito de protesta, de rabia que intenta remover conciencias por algo importante ¿Cómo piensas pagar la luz el resto de tu vida? ¿Haciendo el culo gordo a los mismos de siempre? ¿ O ", React.createElement(_components.a, {
    href: "http://lasindias.net/indianopedia/Distribuido"
  }, "creando un nuevo mundo"), " que nos permita ", React.createElement(_components.a, {
    href: "http://lasindias.com/una-vida-interesante/"
  }, "vivir una vida interesante"), "?"), "\n", React.createElement(_components.p, null, "Off-topic: Mañana no habrá curso de control, un descanso nos viene bien a todos, aprovechad para reflexionar sobre esto."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
